<template>
  <div>
    <Panel module="Treinamento" :showFilter="false" :showSearch="false" :showButtons="false">
      <div slot="content-main">
        <br>
        <CourseView />
      </div>
    </Panel>
  </div>
</template>

<script>
import Panel from "@nixweb/nixloc-ui/src/component/layout/Panel";
import CourseView from "@nixweb/nixloc-ui/src/component/training/CourseView";

import { mapMutations } from "vuex";

export default {
  name: "Dashboard",
  components: {
    Panel,
    CourseView
  },
  mounted() {
    this.removeLoading(["panel"]);
  },
  methods: {
    ...mapMutations("generic", ["removeLoading"]),
  }
};
</script>
